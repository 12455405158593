.header-wrapper {
    background-image: url('/public/images/steel-plate.jpg');
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.25%;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.8);
}


.header-wrapper h2 {
    font-size: 4rem;
    color:orange
}

.header-wrapper h3 {
    font-size: 3rem;
    color:orange
}

.header-wrapper p {
    width: 75%;
    font-size: 1.2rem;
    color:orange
}
.header-text {
    display: flex;
    justify-content: center;
}

.header-text {
    text-align: center;
}

.header-wrapper nav a:hover{
   color:orange;
   text-decoration: underline;
}

@media screen and (max-width: 600px) {
    .header-wrapper h2 {
        text-align: center;
        font-size: 2rem;

    }
}
