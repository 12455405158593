.footer-wrapper {
  background-image: url('/public/images/steel-plate.jpg');
    background-color: black;
    color:white;
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: space-evenly;
    border-radius: 15px;
    margin: 0.25%;
}

.address-wrapper {
    
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
    
   
}

.address-body p {
    text-align: center;
    font-size: 1.2rem;
}




.opening-hours-wrapper {
    margin-top: 0.5%;
    text-align: center;
    margin-right: 1rem;
    
   
}

.facebook-wrapper {
    margin-top: 1rem;
    font-weight: bold;
  }
  
  a {
    font-size: 1.2rem;
    font-style: italic;
    color: white;
  }
  P {text-align: center;}


  .button {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: lightblue;
    color: black;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: gold;
    border-radius: 3rem;
    transition: all 0.2s ease-in-out;
  }
  
  .button:hover {
    background-color: orange;
    ;
  }
  
  .url {
    margin-top: 1rem;
    font-size: 1rem;
    color: #fff;
  }



  @media screen and (max-width: 600px) {
    .footer-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 48rem;
    }


    
   
  }



  @media screen and (min-width: 601px) {
    .address-wrapper {
      margin-bottom: 16rem;
    }
  }
  